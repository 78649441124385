import swal from 'sweetalert2';

export function formatMoney(amount) {
  return amount.toFixed(2).replace('.', ',');
}

export const defaultAlertSettings = {
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Verwijderen',
  confirmButtonColor: "#c9302c",
  cancelButtonText: 'Annuleren',
  animation: false,
}

export async function setLocalStorageItemsByInputs($inputs) {
  // re-populate local storage
  let products = [];

  $inputs.each(function() {
    let valNum = parseInt($(this).val());
    if (valNum > 0) {
      products.push({ id: $(this).data('product-id'),
                      title: $(this).data('product-title'),
                      num: valNum,
                      price_cents: $(this).data('product-price-cents'),
                      deposit_cents: $(this).data('product-deposit-cents'),
                    });
    }
  });

  await localForage.setItem('basket', products);

  return products;
}

export async function alert(title, html, dialogOptions) {
  return await swal.fire({
    title,
    html,
    // confirmButtonText: 'OK',
    // reverseButtons: true,
    ...dialogOptions,
  });
}

export async function confirm(title, html, dialogOptions) {
  return await swal.fire({
    title,
    html,
    showCancelButton: true,
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Verwijderen',
    confirmButtonColor: '#dc3545',
    reverseButtons: true,
    allowOutsideClick: false,
    ...dialogOptions,
  });
}
