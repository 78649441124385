import './account.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import * as util from '../../shared/utilities.es';
import * as wrdauth from '@mod-wrd/js/auth';
import $ from 'jquery';

window.addEventListener("wh:wrdauth-loginfailed", evt => {
  dompack.stop(evt);
  util.alert('De ingevulde inloggegevens zijn incorrect');
});

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-account')) {
    return;
  }

  $('.js-repeat').click(async function(evt) {
    dompack.stop(evt);

    const id = parseInt($(this).data('id')) || 0;

    const confirm = await util.confirm(
      'Bestelling herhalen',
      `Weet u zeker dat u de bestelling van ${$(this).data('title')} wilt herhalen?<br><br>Let op: dit verwijdert alle huidige producten uit uw winkelmandje.`,
      { confirmButtonText: 'Herhalen',
        confirmButtonColor: '#198754',
      }
    );

    if (confirm.isConfirmed) {
      localForage.setItem('basket', JSON.parse($(this).get(0).dataset.products));
      await util.alert(
        'Producten toegevoegd',
        `De producten uit de eerdere bestelling zijn toegevoegd aan uw winkelmandje.<br><br>Klik op 'OK' om door te gaan naar de webshop.`);
      location.href = whintegration.config.site.pages.webshop;
    }
  });

  if (dompack.debugflags['debug']) {
    // window.setTimeout(() => $('.js-repeat').trigger('click'), 200);
  }
});
