import './order.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import { RPCFormBase, registerHandler } from '@mod-publisher/js/forms';
import * as wrdauth from '@mod-wrd/js/auth';
import * as util from '../../shared/utilities.es';

// vendor
import $ from 'jquery';
require("datatables.net")(window, $);
window.localForage = require('localforage'); // for testing purposes

// cached
let products = [];
let $numInputs = [];

class Form extends RPCFormBase {
  constructor(node) {
    super(node);
    this.node = node;

    window.addEventListener("wh:wrdauth-loginfailed", evt => {
      evt.preventDefault();
      console.error(evt);
      util.alert('Fout bij inloggen', 'Het ingevulde e-mailadres en/of wachtwoord is incorrect');
    });

    dompack.onDomReady(() => {
      $('.wh-form__fieldgroup[data-wh-form-group-for="will_pick_up"]').addClass('mt-4');

      $('.wh-form__fieldgroup[data-wh-form-group-for="password"] .wh-form__fieldline').append(`
        <span toggle="#order-password" class="fa fa-fw fa-eye field-icon toggle-password"></span>
      `);

      $(".toggle-password").click(function() {
        $(this).toggleClass("fa-eye fa-eye-slash");
        let input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });
    });

    // try to get data from local storage, if any
    // this.fillByLocalStorage();

    this.elements.send_copy.checked = true;

    if (dompack.debugflags['debug']) {
      this.elements.conditions.checked = true;
      // window.setTimeout(() => { $('.wh-wrdauth__loginbutton').get(0).click(); }, 500);
    }
  }

  // async fillByLocalStorage() {
  //   let userData = await localForage.getItem('userdata');
  //   if (userData && userData.length > 0) {
  //     for (const userCell of userData) {
  //       let $el = $('#' + userCell.id);
  //       if (!$el.length)
  //         continue;

  //       $el.val(userCell.value);
  //     }
  //   } else if (dompack.debugflags['debug']) {
  //     this.elements.firstname.value = 'Wouter';
  //     this.elements.lastname.value = 'Hendriks';
  //     this.elements.email.value = 'testuser@beta.webhare.net';
  //     this.elements.phone.value = '06-12345678';
  //     this.elements.address_street.value = 'Straat';
  //     this.elements.address_nr_detail.value = '1';
  //     this.elements.address_zip.value = 'Postcode';
  //     this.elements.address_city.value = 'Plaatsnaam';
  //   }
  // }

  getFormExtraSubmitData() {
    return { products: getProductsFromInputs() };
  }

  onSubmitSuccess() {
    // let userData = [];
    // for (const el of this.elements) {
    //   if (el.id !== '') {
    //     let $el = $('#' + el.id);
    //     // only interested in text, e-mail and textarea
    //     if ($el.attr('type') !== 'text'
    //         && $el.attr('type') !== 'email'
    //         && $el.get(0).tagName !== 'TEXTAREA') {
    //       continue;
    //     }

    //     if ($el) {
    //       userData.push({ 'id': el.id
    //                     , 'value': $el.val()
    //                     });
    //     }
    //   }
    // }

    // localForage.setItem('userdata', userData);
  }
}

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-order')) {
    return;
  }

  registerHandler('biologisch_made_easy:order', node => new Form(node));

  // setupOrderPickup();
  setupProductsTable();

  let $conditionsLabel = $('.wh-form__optionlabel[for="order-conditions"]');
  $conditionsLabel.html($conditionsLabel.html().replace('de voorwaarden', `<a href="${whintegration.config.obj.conditionslink}" target="_blank">de voorwaarden</a>`));

  let $toAccountLinkSpan = $('.wh-form__fieldgroup--richtext[data-wh-form-group-for="thankyoutext"] span');
  $toAccountLinkSpan.replaceWith(`<a href="${whintegration.config.site.pages.account}">${$toAccountLinkSpan.text()}</a>`);
});

// function setupOrderPickup() {
//   let $willPickUp = $('.order__pickup input[type="checkbox"]');
//   let $addressFields = $('.order__address input');

//   $addressFields.attr('required', 'required');

//   $willPickUp.change(() => {
//     let willPickUp = $willPickUp.is(":checked") === true;

//     $('.order__address').toggleClass('hide', willPickUp);

//     $addressFields.each(function() {
//       $(this).attr('required', willPickUp ? null : 'required');
//     });
//   });
// }

async function setupProductsTable() {
  try {
    products = await localForage.getItem('basket');
    if (!products || products.length == 0) {
      throw 'No items';
    }

    let rows = '';
    let totalPrice = 0.0;

    for (let product of products) {
      // get product details from db
      let productDetails = whintegration.config.obj.products.find(x => x.wrd_id == product.id);
      if (productDetails) {
        rows = rows + `<tr>
                         <td data-label="Aantal">
                           <input class="order__numinput"
                                  type="number"
                                  min="0"
                                  max="999"
                                  maxlength="3"
                                  data-product-id="${productDetails.wrd_id}"
                                  data-product-title="${productDetails.title}"
                                  data-product-price="${productDetails.price}"
                                  data-product-price-cents="${productDetails.price_cents}"
                                  data-product-deposit="${productDetails.deposit_money}"
                                  data-product-deposit-cents="${productDetails.deposit_money_cents*100}"
                                  value="${product.num}"
                                  />
                         </td>
                         <td data-label="Eenheid">${productDetails.unit}</td>
                         <td data-label="Product">
                           <!-- <a href="${whintegration.config.obj.productslink}#${productDetails.wrd_id}">${productDetails.title}</a> -->
                           ${productDetails.title}
                         </td>
                         <td data-label="Producent">${productDetails.producer}</td>
                         <td data-label="Prijs" style="white-space: nowrap;">€ ${util.formatMoney(productDetails.price)}</td>
                         <td data-label="Statiegeld" style="white-space: nowrap;">€ ${util.formatMoney(productDetails.deposit_money)}</td>
                         <td data-label="Opmerkingen">${productDetails.remarks}</td>
                       </tr>`;

        totalPrice += (productDetails.price * product.num) + (productDetails.deposit_money * product.num);

        product.price = productDetails.price;
        product.deposit_money = productDetails.deposit_money;
      } else {
        console.error("could not find product #" + product.id);
      }
    }

    $('.order__productstable tbody').append(rows);

    $numInputs = $('.order__numinput');
    $numInputs.bind('input', onNumInputChange);
    updateTotalPrice();

    $('.order__productstable').DataTable({
      "paging": false,
      "ordering": false,
      "info": false,
      "searching": false,
      'language': {
        'emptyTable': 'Geen producten geselecteerd'
      },
      "scrollX": true,
    });

    $('.js-orderform').addClass('show');
  } catch (err) {
    console.error(err);
    $('.order__noitems').addClass('show');
  }
}

function getProductsFromInputs() {
  let products = [];
  $numInputs.each(function() {
    products.push({
      id: parseInt($(this).data('product-id')),
      num: parseInt($(this).val()),
    })
  });

  return products;
}

function updateTotalPrice() {
  let totalPrice = 0.0;

  $numInputs.each(function() {
    let price = $(this).data('product-price');
    let num = parseInt($(this).val());
    if (num > 0) {
      let deposit_money = $(this).data('product-deposit');
      totalPrice += (price * num) + (deposit_money * num);
    }
  })

  $('.js-total-price').text('€ ' + util.formatMoney(totalPrice));
}

async function onNumInputChange() {
  console.log($(this));
  if ($(this).val() === '0')
    $(this).val('');

  await util.setLocalStorageItemsByInputs($numInputs);

  updateTotalPrice();
}
